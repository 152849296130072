import domReady from '@roots/sage/client/dom-ready';
import 'bootstrap';
import Isotope from 'isotope-layout';
// import Swiper from 'swiper/bundle';
import {gsap} from "gsap";
import ScrambleTextPlugin  from 'gsap/ScrambleTextPlugin.js';
import ScrollSmoother  from 'gsap/ScrollSmoother.js';
import ScrollTrigger  from 'gsap/ScrollTrigger.js';
import Lenis from '@studio-freight/lenis'
import Splitting from 'splitting';
import imagesLoaded from 'imagesloaded';

gsap.registerPlugin(ScrambleTextPlugin, ScrollSmoother, ScrollTrigger);
/**
 * Application entrypoint
 */

function hideLoader() {
  const loader = document.querySelector('#loader');
  window.addEventListener('load', function() {
    setTimeout(function() {
      loader.style.opacity = '0';
    }, 600);
  });
  setTimeout(() => {
    loader.style.opacity = 0;
  }, 600);
}
window.addEventListener('pageshow', function(event) {
  if (event.persisted) {
    loader.style.opacity = '0';
  }
});

function transitionLoader(event) {
  // Prevent the default behavior of the anchor element
  //when click in an 'a'
  if (event) {
    event.preventDefault();
  }

  // Get the href content of the clicked a tag, or the closest a tag if the clicked element is not an a tag
  const href = event.target.closest('a').href;
  console.log(href);

  // Get the element that was clicked
  const clickedElement = event.target;
  console.log(clickedElement);

  // Add the "loader" class to the #loader element
  const loader = document.querySelector('#loader');
  loader.style.opacity = 1;

  // Set a timeout to navigate to the link after 600ms
  setTimeout(() => {
    window.location.href = href;
  }, 600);
}




//if body dont has class home
// if (!document.body.classList.contains('home') && !document.body.classList.contains('page-template-template-new-home')) {
//   const lenis = new Lenis()

//   lenis.on('scroll', ScrollTrigger.update)

//   gsap.ticker.add((time)=>{
//     lenis.raf(time * 1000)
//   })

//   gsap.ticker.lagSmoothing(0)
//   console.log(lenis)
// }





domReady(async () => {

  const viewportHeight = window.innerHeight;
  const footer = document.querySelector('footer');
  animUp();
  footerHeight();
  addFullyScrolledClass();
  customCursor();
  shotSlider();
  masonryShots();
  
  contactForm();
  virtualTourReveal();
  menu();
  debug();
  hideShadow();
  heightMasonryBlog();
  
  const anchors = document.querySelectorAll('a:not([target="_blank"])');
    anchors.forEach(anchor => {
    anchor.addEventListener('click', transitionLoader);
  });
    hideLoader();


  function debug() {
    if (document.body.classList.contains('single-shots')){
      const sliderWrapper = document.querySelector('.shot__slider__wrapper');
      const debugElem = document.querySelector('.shot__slider__debug');
    
        function updateSliderWrapperHeight() {
          sliderWrapper.style.height = `${viewportHeight}px`;
          // debugElem.textContent = `Slider wrapper height: ${viewportHeight}px`;
        }
    
        // Update slider wrapper height on load and resize
        window.addEventListener('load', updateSliderWrapperHeight);
        window.addEventListener('resize', updateSliderWrapperHeight);
    }
    }
  


  if ('ontouchstart' in window) {
    document.querySelector('.customCursor').remove();
    // const swiperBottom = document.querySelector('.swiper-bottom');
    // swiperBottom.style.bottom = '128px';
    
    
  } else {
    
  }

  function mobileVideos(){
    function isMobilePortrait() {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      const isPortrait = window.matchMedia("(orientation: portrait)").matches;
      return isMobile && isPortrait;
    }
    if (document.body.classList.contains('home')) {
      if (isMobilePortrait()) {
        
        // Add the d-none class to the .home__video__desktop element
        const homeVideoDesktop = document.querySelector('.home__video__desktop');
        homeVideoDesktop.classList.add('d-none');
      } else {
        // Add the d-none class to the .home__video__mobile element
        const homeVideoMobile = document.querySelector('.home__video__mobile');
        homeVideoMobile.classList.add('d-none');
      }
    }
  }


  function addFullyScrolledClass() {
    const body = document.querySelector('body');
    const html = document.documentElement;

    

    const footer = document.querySelector('footer');
    const footerHeight = footer.offsetHeight;

    // Calculate the height of the body minus the height of the viewport
    const bodyHeight = body.offsetHeight;
    
    const heightDifference = bodyHeight - viewportHeight;

    // Check if the height difference is scrolled
    function toggleFullyScrolledClass() {
      const scrollPosition = window.scrollY || html.scrollTop;
      const brandInFooterHeight = document.querySelector('.footer__logo').offsetHeight;
      if (scrollPosition >= heightDifference - brandInFooterHeight) {
        
        // Add the fullyScrolled class to the body element
        body.classList.add('fullyScrolled');
        // console.log('fullyScrolled')
      } else {
        // Remove the fullyScrolled class from the body element
        body.classList.remove('fullyScrolled');
        // console.log('NO')
      }
      // console.log(`scrollPosition: ${scrollPosition}px heightDifference: ${heightDifference}px viewportHeight: ${viewportHeight}px bodyHeight: ${bodyHeight}px brandInFooterHeight: ${brandInFooterHeight}px`)
    }


    window.addEventListener('scroll', toggleFullyScrolledClass);
    // Call the toggleFullyScrolledClass function on DOM ready
  }

// Call the addFullyScrolledClass function on scroll
  window.addEventListener('scroll', addFullyScrolledClass);
  


  function contactForm() {
    
    if (document.body.classList.contains('page-template-template-quote')) {
      const interiorAniCheckbox = document.querySelector('input[value="Interior animation"]');
      const exteriorAniCheckbox = document.querySelector('input[value="Exterior animation"]');
      const interiorRenCheckbox = document.querySelector('input[value="Interior rendering"]');
      const exteriorRenCheckbox = document.querySelector('input[value="Exterior rendering"]');
      const animationSeconds = document.querySelector('.animationSeconds');
      const numberImages = document.querySelector('.numberImages');
    
      // Add event listeners to the interior and exterior checkboxes
      interiorAniCheckbox.addEventListener('change', toggleAnimationSeconds);
      exteriorAniCheckbox.addEventListener('change', toggleAnimationSeconds);
  
      interiorRenCheckbox.addEventListener('change', toggleNumberImages);
      exteriorRenCheckbox.addEventListener('change', toggleNumberImages);
    
      function toggleAnimationSeconds() {
        // Check if either checkbox is checked
        if (interiorAniCheckbox.checked || exteriorAniCheckbox.checked) {
          // Remove the .d-none class from the .animation-seconds element
          animationSeconds.classList.remove('d-none');
        } else {
          // Add the .d-none class to the .animation-seconds element
          animationSeconds.classList.add('d-none');
        }
      }
  
      function toggleNumberImages() {
        
        if (interiorRenCheckbox.checked || exteriorRenCheckbox.checked) {
          
          numberImages.classList.remove('d-none');
        } else {
          
          numberImages.classList.add('d-none');
        }
      }
    }
  }

  function masonryShotsAnimation() {
    // Select all grid-items
    const gridItems = document.querySelectorAll(".grid-item");

    let tl;
    if (document.body.classList.contains('shots-template-default')) {
      tl = gsap.timeline({
      });
    } else {
      tl = gsap.timeline({
        paused: true,
        scrollTrigger: {
          trigger: '.shot__list',
          start: 'top 80%', // Inicia la animación cuando el 80% del elemento está visible en la ventana
          end: 'bottom 20%', // Termina la animación cuando el 20% del elemento está visible en la ventana
          toggleActions: 'play none none none', // Controla la dirección de la animación
          markers: false,
          onEnter: () => {
            tl.play();
          },
          // onLeaveBack: () => {
          //   tl.reverse();
          // }
        },
      });
    }

    // Add animation to each grid-item
    gridItems.forEach((item) => {
      tl.fromTo(
        item, {
          opacity: 0,
          y: 50,
          filter: "blur(10px)"
        }, {
          opacity: 1,
          y: 0,
          filter: "blur(0px)",
          duration: 0.5
        },
        "-=0.25" // stagger delay
      );
    });
  }

  function masonryShots() {
    if (document.querySelector('.grid') === null) return;
    
    const elem = document.querySelector('.grid');
    imagesLoaded(elem, function() {
      const iso = new Isotope(elem, {
        // options
        itemSelector: '.grid-item',
        percentPosition: true,
        masonry: {
          // use element for option
          columnWidth: '.grid-sizer',
          gutter: 16,
          horizontalOrder: true
        }
      });

  
    });

    masonryFilter();
    masonryShotsAnimation();
  }

  function masonryFilter() {

    const filtersElem = document.querySelector('.filters');
    const filterButtons = document.querySelectorAll('.filters .filter');
    const elem = document.querySelector('.grid');

    const iso = new Isotope(elem, {
      // options
      itemSelector: '.grid-item',
      percentPosition: true,
      masonry: {
        // use element for option
        columnWidth: '.grid-sizer',
        gutter: 16,
      }
    });

    if (filtersElem === null) return;
    else {
      // Initialize Isotope instance
      imagesLoaded(elem, function() {
        const iso = new Isotope(elem, {
          // options
          itemSelector: '.grid-item',
          percentPosition: true,
          masonry: {
            // use element for option
            columnWidth: '.grid-sizer',
            gutter: 16,
          }
        });
  
    
      });
  
      // Filter items on hashchange
      window.addEventListener('hashchange', function() {
        const filterValue = location.hash.slice(1);
        iso.arrange({ filter: filterValue });
        updateFilterButtons(filterValue);
      });
  
      // Filter items on click
      filterButtons.forEach(function(button) {
        button.addEventListener('click', function(event) {
          event.preventDefault();
          const filterValue = this.getAttribute('data-filter');
          location.hash = filterValue;
        });
      });
  
      // Update filter buttons on hashchange
      function updateFilterButtons(activeFilter) {
        filterButtons.forEach(function(button) {
          const filterValue = button.getAttribute('data-filter');
          if (filterValue === activeFilter) {
            button.classList.add('checked');
          } else {
            button.classList.remove('checked');
          }
        });
      }
  
      // Add the 'checked' class to the first '.filter__item' element on page load
      window.addEventListener('load', function() {
        const firstFilterItem = document.querySelector('.filter__item');
        firstFilterItem.classList.add('checked');
      });
  
      // Initialize filter buttons
      updateFilterButtons(location.hash.slice(1));
    }
  }

  function heightMasonryBlog(){
    
      if (document.body.classList.contains('blog') || document.body.classList.contains('single-post')) {
        const gridItemTitles = document.querySelectorAll('.grid-item__title');
        let maxHeight = 0;
        gridItemTitles.forEach(title => {
          const titleHeight = title.offsetHeight;
          if (titleHeight > maxHeight) {
            maxHeight = titleHeight;
          }
        });
        gridItemTitles.forEach(title => {
          title.style.height = `${maxHeight}px`;
        });
      }
    
  }

  function jumboHeight() {
    const jumbo = document.querySelector('.jumbo');
    const jumboHeight = window.innerHeight;
    jumbo.style.height = jumboHeight + 'px';
  }

  function headerHeight() {
    const header = document.querySelector('.header');
    const headerPlaceholder = document.querySelector('.header--placeholder');
    const headerHeight = header.offsetHeight;
    headerPlaceholder.style.height = headerHeight + 'px';

  }
  function setPositionFooter(){
    
    
    // console.log(heightDifference, viewportHeight, footerHeight);
    if (document.body.classList.contains('home')) {
      footer.style.position = 'relative';
      footer.style.height = '100vh';
      footer.style.backgroundColor = 'black';
      footer.style.zIndex = '2';
      footer.style.marginTop = '-100vh';
    }else {

    }      

  }

  // Call the toggleFullyScrolledClass function on scroll
  setPositionFooter();
  function footerHeight() {
    
    
    footer.style.position = 'static';
    const footerHeight = footer.offsetHeight;
    const prevDiv = footer.previousElementSibling;
    const contactHome = document.querySelector('.boira-sects.contact');
    footer.style.position = 'fixed';
    const heightDifference = footerHeight - viewportHeight;
    if (document.body.classList.contains('home')) {
      
      contactHome.style.position = 'relative';
      contactHome.style.background = 'black';
      contactHome.style.marginBottom = viewportHeight + 'px';
      contactHome.style.paddingBottom = '120px';
    } else if((document.body.classList.contains('page-template-template-new-home'))) {

    }
    else {
      if (heightDifference >= 0 || 'ontouchstart' in window) {
        footer.style.position = 'static';
        console.log('static')
      } else {
        prevDiv.style.position = 'relative';
        prevDiv.style.zIndex = '2';
        prevDiv.style.background = 'black';
        prevDiv.style.marginBottom = viewportHeight + 'px';
        prevDiv.style.paddingBottom = '120px';
        prevDiv.style.boxShadow = '0px 50px 50px 20px black';
        footer.style.position = 'fixed';
        console.log('fixed')
      }

    }

    

  }
  
  function customCursor() {
    // Hide the default cursor
    document.body.style.cursor = 'none';
  
    // Create a white circle cursor
    const cursor = document.querySelector('.customCursor');
    cursor.style = `
      position: fixed;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: white;
      pointer-events: none;
      z-index: 9999;
      mix-blend-mode: difference;
      transition: transform 0.2s, background-color 0.2s, height 0.2s, width 0.2s;
      transform: translate(-50%, -50%);
    `;
    document.body.appendChild(cursor);
  
    // Update the cursor position on mousemove
    document.addEventListener('mousemove', function(event) {
      cursor.style.left = event.clientX + 'px';
      cursor.style.top = event.clientY + 'px';
      cursor.style.transform = 'translate(-50%, -50%)';
    });
  
    // Add hover effect to a tags
    const aTags = document.querySelectorAll('a, [data-hover]');
    aTags.forEach(function(a) {
      a.addEventListener('mouseenter', function() {
        cursor.style.width = '64px';
        cursor.style.height = '64px';
      });
      a.addEventListener('mouseleave', function() {
        cursor.style.backgroundColor = 'white';
        cursor.style.width = '16px';
        cursor.style.height = '16px';
      });
    });

    const dataHide = document.querySelectorAll('[data-hide]');
    dataHide.forEach(function(a) {
      a.addEventListener('mouseenter', function() {
        cursor.style.width = '0px';
        cursor.style.height = '0px';
      });
      a.addEventListener('mouseleave', function() {
        cursor.style.backgroundColor = 'white';
        cursor.style.width = '16px';
        cursor.style.height = '16px';
      });
    });
  
    // Add hover effect to Swiper navigation buttons if body has class .single-shots
    if (document.body.classList.contains('single-shots')) {
      function handleCursor(selector, arrowSelector) {
        const button = document.querySelector(selector);
        const arrow = document.querySelector(arrowSelector);
      
        button.addEventListener('mouseenter', function() {
          cursor.style.width = '64px';
          cursor.style.height = '64px';
          arrow.classList.remove('d-none');
        });
      
        button.addEventListener('mouseleave', function() {
          cursor.style.width = '16px';
          cursor.style.height = '16px';
          arrow.classList.add('d-none');
        });
      }
      
      handleCursor('.swiper-button-next', '.customCursor__arrowRight');
      handleCursor('.swiper-button-prev', '.customCursor__arrowLeft');
      handleCursor('.swiper-more', '.customCursor__arrowBottom');
    }
  }

  function menu() {
    const menuIcon = document.querySelector('.menu--icon');
    const header = document.querySelector('.header');
    const footerInHeader = document.querySelector('.footer__header');
    const footerRow = document.querySelector('.footer__header .footer__row');
    const body = document.querySelector('body');
    const brand = document.querySelector('.brand');
    footerInHeader.style.display = '';
    
    
    const footerHeight = footerRow.offsetHeight;
    const heightDifference = viewportHeight - footerHeight;



    if (heightDifference >= 120) {
      
    }else{
    
      footerRow.style.height = `${footerHeight + 120}px`;
      

    }

    let isMenuActive = false; // Initialize a flag to track the menu state
    menuIcon.addEventListener('click', function() {
      const elements = [ header,this,brand, footerInHeader,];
      const timeout = 300; 
      let delay = 0;

      if (!isMenuActive) {
        elements.forEach(element => {
          element.classList.add('active');
          //if heightDifference <= 120 dont add class active to brand
          if (heightDifference <= 120) {
            brand.classList.remove('active');
            brand.style.position = 'absolute';
            brand.style.top = '18px';
            brand.style.left = '12px';
            brand.style.zIndex = '9999';
          }
        });
        isMenuActive = true;
        body.classList.add('no-scroll');
      } else { 
        elements.reverse().forEach(element => { 
          setTimeout(() => {
            element.classList.remove('active');
          }, delay);
          delay += timeout; 
        });
        isMenuActive = false;
        body.classList.remove('no-scroll');
      }
    });
  }
    
  function shotSlider() {
    if (document.body.classList.contains('single-shots')) {
      var swiperOptions = {
        loop: true,
        speed: 1000,
        spaceBetween: 300,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      };
  
      // Check for touch support
      if ('ontouchstart' in window) {
        swiperOptions.navigation = {
          nextEl: '.swiper-button-next.is--mobile',
          prevEl: '.swiper-button-prev.is--mobile',
        };
        
      } else {
        swiperOptions.navigation = {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        };
      }
  
      var swiper = new Swiper('.shot__slider', swiperOptions);
    }
    
  }
  

  function goToShots() {
    if (document.body.classList.contains('single-shots')) {
      const shotList = document.querySelector('.shot__list');
      const swiperMore = document.querySelector('.swiper-more');
      swiperMore.addEventListener('click', function() {
        
        const shotListTop = shotList.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: shotListTop,
          behavior: 'smooth'
        });
      });
    }
  }

  function animUp() {
    const elements = document.querySelectorAll('.anim__up');

    elements.forEach((element) => {
      const childElements = element.querySelectorAll('.anim__up__child');

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: element,
          start: 'top 80%', // Inicia la animación cuando el 80% del elemento está visible en la ventana
          end: 'bottom 20%', // Termina la animación cuando el 20% del elemento está visible en la ventana
          toggleActions: 'play none none none', // Controla la dirección de la animación
        },
      });

      childElements.forEach((child, index) => {
        tl.fromTo(
          child, {
            opacity: 0,
            y: 50,
          }, {
            opacity: 1,
            y: 0,
            duration: 1, // Duración de la animación en segundos
            ease: 'power2.out', // Efecto de aceleración
          },
          index * 0.4 // Aplica un stagger de 0.4 segundos entre cada elemento
        );
      });
    });
  }

  function virtualTourReveal() {
    const imageTourElements = document.querySelectorAll('.image__tour');

    // Itera sobre cada elemento .image__tour
    imageTourElements.forEach(imageTour => {
        const decoration = imageTour.querySelector('.image__tour__decoration');
        const iframe = imageTour.querySelector('iframe');

        // Agrega un evento click a .image__tour__decoration
        decoration.addEventListener('click', () => {
            // Toggle de la clase .d-none
            decoration.classList.toggle('d-none');
            // Cambia la opacidad del iframe
            iframe.classList.toggle('d-none');
        });
    });
  }

goToShots();

if (document.body.classList.contains('page-template-template-new-home')) {
  // ScrollTrigger.normalizeScroll(true);
  let tlexperimg = gsap.timeline();
  tlexperimg.from(".home__designing__col img", {
    "will-change": "transform",
    transformOrigin: "center center",
    y: 100,
    duration: 2,
    zIndex: 1,
    opacity: 0,
    stagger: 0.4,
    markers: false,
    
  });
  
  ScrollTrigger.create({
    trigger: ".home__designing__col",
    start: "top center", 
    end: "bottom 60%",
    toggleActions: "play none none none",
    ease: "power4.in",
    animation: tlexperimg,
    // markers: true,


  });


  let property = gsap.timeline();
  property.from(".home__property img", {
    "will-change": "transform",
    transformOrigin: "center center",
    y: 100,
    duration: 2,
    zIndex: 1,
    opacity: 0,
    stagger: 0.4,
    markers: false,
    
  });
  
  ScrollTrigger.create({
    trigger: ".home__property",
    start: "top center", 
    end: "bottom 60%",
    toggleActions: "play none none none",
    ease: "power4.in",
    animation: property,
    // markers: true,


  });




}


function hideShadow(){
  const bodyHeight = document.body.offsetHeight;
  window.addEventListener("scroll", () => {
    
    const footerHeight = document.querySelector('footer').offsetHeight;
    // console.log('a')
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    console.log(`scrollPosition: ${scrollPosition} footerHeight: ${footerHeight} bodyHeight ${bodyHeight}`)

    if(document.body.classList.contains('home')) {
      if (scrollPosition > bodyHeight - footerHeight - 400) {
        document.querySelector('.boira-text-blur').classList.add('d-none');
        console.log('hidden')
      } else {
        document.querySelector('.boira-text-blur').classList.remove('d-none');
      }
    }

  });
}




});


/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
